.filterToggle {
  width: 12.856rem;
  height: 2.621rem;
  border-radius: 39.9px !important;
  border: 1px solid #000000;
  label {
    font-style: normal;
    font-weight: $medium-weight;
    font-size: $font-size-small;
    color: black;
    line-height: 24px;
    border: none;
    @include centerElements;
    border-radius: 39.9px !important;
  }
  .btn-check:checked + label {
    background-color: #1976d2;
    color: $third-base-color;
  }
}
