.accordian-content {
  .transcript-wrapper {
    position: relative;
    @include scrollStyle(8px, auto);
    .fullscreen-code-editor-btn {
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 1;
    }
    @include editorStyling();
  }
}
