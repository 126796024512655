@mixin dropdownStyle {
  li {
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: #1e2125;
      background-color: #e9ecef;
    }
  }
  p {
    padding-inline: 0;
    &:hover {
      background: none;
    }
    margin: 0;
  }
  .dropdown-menu {
    width: 10.615rem;
    overflow: hidden;
    z-index: 1;
    display: none;
    position: absolute;
  }
}

.tableDropdown {
  @include dropdownStyle;
  position: relative;
}

.dropdown-menu.show {
  display: block;
}
