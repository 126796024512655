.public-report-container {
  position: relative;
  padding: 2rem;
  height: 100vh;
  .report-wrapper {
    background: #ffffff;
    height: 100%;
    display: flex;
    column-gap: 1rem;
    padding-bottom: 1rem;
    .reportCard-wrapper {
      position: relative;
      width: 75%;
      .hiringDropdown {
        .MuiSelect-select {
          display: block;
          @include textEllipse();
          width: 80%;
        }
      }
      .action-buttons-container {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        padding: 5px;
      }
      .candidate-nav-container {
        display: flex;
        column-gap: 1rem;
        justify-content: flex-end;
        margin-bottom: 1rem;
      }
    }
  }
  .hiringStageSelect {
    position: relative;
    width: 182px;
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: #000000;

    .circleIndicator {
      border-radius: 3px;
      width: 8px;
      height: 8px;
    }
  }

  .review-container {
    height: fit-content;
    padding: 0 0 1rem 0;
    position: relative;
    .content-wrapper {
      margin-bottom: 1rem;
      display: flex;
      .questions-panel {
        overflow: hidden;
        height: 100%;
        // width: 20%;
        .ques-list {
          overflow-y: auto;
          height: calc(100% - 48px);
          @include scrollStyle(8px, auto);
          padding-top: 0;
        }
      }

      .view-content-container {
        height: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        @include scrollStyle(8px, auto);
        img {
          max-width: 300px;
          max-height: 300px;
        }

        p {
          margin-bottom: 0;
        }

        .video-answer-container {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          .video-trans-wrapper {
            display: flex;
            height: 343px;
            .video-player {
              width: 50%;
              height: 100%;
              max-height: 100%;
              border-radius: 8px;
              object-fit: contain;
              .video-react-big-play-button {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            hr {
              width: 0.5px;
              margin-left: 1rem;
            }
            .transcript-wrapper {
              position: relative;
              width: 50%;
              height: 100%;
              border-radius: 8px;
              @include scrollStyle(8px, auto);
              .fullscreen-code-editor-btn {
                position: absolute;
                top: 2px;
                right: 2px;
                z-index: 1;
              }
              @include editorStyling();
            }
          }
          .summary-container {
            height: 160px;
            padding: 1rem;
            overflow: hidden;
            .text {
              overflow-y: auto;
              @include scrollStyle(8px, auto);
              height: calc(100% - 28px);
            }
          }
          .rating-explaination-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem;
            max-height: 244.67px;
            .explaination-header {
              margin-top: 1rem;
            }
            .text {
              overflow: hidden auto;
              @include scrollStyle(8px, auto);
            }
          }
        }
        .customFormControlLabel > span:nth-of-type(2) {
          width: 100%;
        }
      }
    }
  }
}
