.candidate-details-container {
  height: 100vh;
  padding: 1rem;
  .validation-red-border-color {
    border-color: #ff5630;
  }
  .form-container {
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .personal-info-content {
      width: 30%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;
    }
    .additional-content {
      width: 30%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;
    }
  }
}
