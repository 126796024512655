.session-timeout-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  .logo {
    width: 112px;
    height: 62px;
  }
}
