.candidate-container {
  .company-logo {
    height: 83px;
    width: 115px;
    object-fit: contain;
    position: relative;
  }

  .image-wrapper {
    position: relative;
    height: 83px;
    width: 115px;
    margin-bottom: 1rem;
  }

  .image-placeholder {
    height: 83px;
    width: 115px;
    filter: blur(8px);
    background: lightgrey;
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 70%);
    }

    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  .mobile-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    color: #818087;
    font-size: 1rem;
  }

  .react-code-input {
    display: flex !important;
    column-gap: 23px !important;
    input {
      display: inline-block;
      text-align: center;
      width: 70px !important;
      border: transparent !important;
      border-radius: 0px !important;
      border-bottom: 1px solid #000 !important;
      box-shadow: none !important;
      font-size: 18px;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
    input:not(.UAC-Input) {
      width: 376px;
      height: 51px;
      padding: 0px 5px 0px 5px;

      &:focus {
        outline: transparent;
      }
    }
  }

  .error-privateCode {
    input {
      border-bottom: 1px solid red !important;
    }
  }

  input {
    height: 20px;
  }
}
