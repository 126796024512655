@mixin firstElementOfType {
  &:first-of-type {
    text-align: left;
  }
}
.tableComponent {
  position: relative;
  box-shadow: 4px 4px 20px 0px #f1f3ff;
  border: 0.94px solid #ececec;
  border-radius: 16px;
  table {
    .empty-test-text {
      tr td {
        width: 100%;
        color: #ff0000;
        text-align: center !important;
      }
    }
    .text-ellipse {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .loader {
    .spinner-border {
      width: 60px;
      height: 60px;
      color: $primary-color;
      z-index: 3;
    }
  }
  .hiringDropdown {
    .MuiSelect-select {
      display: block;
      @include textEllipse();
      width: 80%;
    }
  }
}
