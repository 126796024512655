.candidate-screening-page {
  padding: 24px;
  .image-placeholder {
    height: 83px;
    width: 115px;
    filter: blur(8px);
    background: lightgrey;
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  .company-logo {
    height: 83px;
    width: 115px;
    object-fit: contain;
    position: relative;
  }
  .customFormControlLabel > span:nth-of-type(2) {
    width: 100%;
  }
  .questions-card {
    height: fit-content;
    min-height: 100%;
    padding: 16px;
    margin-top: 16px;
    .question-card-wrapper {
      margin-top: 16px;
      .question-card {
        height: fit-content;
        padding: 16px;
        margin-top: 8px;
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: space-between;
  }
}
