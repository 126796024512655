@mixin centerElements {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin scoreTagStyle {
  padding: 5px 19px;
  width: fit-content;
  background: #eaedf2;
  border-radius: 10px;
  @include centerElements;
}
@mixin scrollStyle($width, $height) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0);
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 10px;
  }
  scroll-behavior: smooth;
}
@mixin textEllipse($maxWidth: 100%) {
  max-width: $maxWidth;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin editorStyling() {
  .myCodeMirror {
    .cm-editor {
      border-radius: 8px;
      padding: 1rem;
    }
    .cm-gutters {
      border-radius: 8px;
    }
    .cm-scroller {
      @include scrollStyle(5px, 5px);
    }
    .cm-content {
      width: calc(100% - 217px);
      text-wrap: wrap;
    }
  }
  .myCodeMirror-Fullscreen {
    .cm-editor {
      border-radius: 8px;
      padding: 2rem 1rem 1rem;
    }
    .cm-gutters {
      border-radius: 8px;
    }
    .cm-scroller {
      @include scrollStyle(5px, 5px);
    }
    .cm-content {
      width: calc(100% - 217px);
      text-wrap: wrap;
    }
  }
}
