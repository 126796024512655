.left-panner {
  padding: 16px;
  padding-bottom: 5px;

  .left-panel-wrapper {
    border: 2px solid rgb(241 241 241 / 73%);
    box-shadow: 4px 4px 10px 0px rgba(176, 174, 174, 0.25);
    border-radius: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    padding-top: 70px;
    .time {
      font-weight: $medium-weight;
    }
    .logo {
      width: 290px;
      height: 132px;
      object-fit: contain;
      margin-bottom: 0;
    }

    .image-wrapper {
      position: relative;
      width: 290px;
      height: 132px;
      margin-bottom: 0;
    }

    .image-placeholder {
      width: 290px;
      height: 132px;
      filter: blur(8px);
      background: lightgrey;
      opacity: 0.7;
      animation: skeleton-loading 1s linear infinite alternate;
    }
    @keyframes skeleton-loading {
      0% {
        background-color: hsl(200, 20%, 70%);
      }

      100% {
        background-color: hsl(200, 20%, 95%);
      }
    }
  }
}

.right-section {
  height: 100%;
  .instructions-wrapper {
    padding: 16px;
    padding-left: 0;
    height: 100%;
    height: calc(100% - 60px);
    .instructions-container {
      height: 100%;
      padding: 23px;
      border-radius: 12px;
      border: 2px solid rgb(241 241 241 / 73%);
      box-shadow: 4px 4px 10px 0px rgba(176, 174, 174, 0.25);
      .subHeader {
        margin-top: 16px;
      }
      .allTheBestText {
        text-align: center;
        margin-top: 16px;
      }
    }
  }
}
