.auth-container {
  .signup_container {
    .signup-card {
      padding: 25px 50px;
      border-radius: 50px;
      background: #fff;
      box-shadow: 1px 27px 60px 0px rgba(0, 0, 0, 0.1),
        6px 108px 108px 0px rgba(0, 0, 0, 0.09),
        13px 243px 146px 0px rgba(0, 0, 0, 0.05),
        22px 432px 173px 0px rgba(0, 0, 0, 0.01),
        35px 675px 189px 0px rgba(0, 0, 0, 0);
    }
    input {
      height: 20px;
    }
    .phoneNumber {
      .MuiSelect-select {
        display: flex;
        column-gap: 3px;
        img {
          object-fit: contain;
        }
      }
    }
    .MuiMenuItem-root.listCountry {
      display: flex;
      column-gap: 3px;
      img {
        object-fit: contain;
      }
    }
  }
}
