.pricing-container {
  // padding-top: 104px;
  padding-top: 40px;
  padding-inline: 22px;
  // background: #eaedf2;
  // background: #eaedf2;
  background: #ffffff;
  // background-color: #eaedf200;
  .header-container {
    width: 100%;
    // min-height: 120px;
    background: #ffffff;
    box-shadow: 193px 172px 103px rgba(0, 0, 0, 0.01),
      108px 97px 87px rgba(0, 0, 0, 0.05), 48px 43px 65px rgba(0, 0, 0, 0.09),
      12px 11px 36px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 28px 18px;
    display: flex;
    flex-direction: column;
    row-gap: 27px;
    overflow: hidden;
    @media only screen and (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
      padding-inline: 80px;
    }
    .header {
      font-style: normal;
      font-weight: $medium-weight;
      line-height: 36px;
      letter-spacing: 0.5px;
      color: #000000;
      margin: 0;
    }
    .subHeader {
      font-style: normal;
      font-weight: $normal-weight;
      // font-size: 19px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #000000;
      margin: 0;
    }
    p {
      margin: 0;
    }
    .startTrialBtn {
      width: 291px;
      height: 66px;
      border-radius: 10px;
      font-style: normal;
      font-weight: $large-weight;
      font-size: 19px;
      line-height: 28px;
      letter-spacing: 0.5px;
    }
  }
  .content-container {
    margin-top: 36px;
    width: 100%;
    height: fit-content;

    border-radius: 10px;
    text-align: center;
    @media only screen and (min-width: $tablet) {
      padding: 37px 54px;
      height: 790px;
    }
    .header {
      display: none;
      @media only screen and (min-width: $tablet) {
        display: block;
        font-style: normal;
        font-weight: $large-weight;
        line-height: 36px;
        color: #00ab55;
        margin: 0;
      }
    }
    .cardWrapper {
      display: flex;
      column-gap: 31px;
      height: fit-content;
      @media only screen and (min-width: $tablet) {
        height: 620px;
      }
      margin-top: 23px;
      .cardLeft {
        display: none;
        @media only screen and (min-width: $tablet) {
          display: block;
          width: 30%;
          height: 100%;
          background: #00ab55;
          box-shadow: 218px 153px 106px rgba(102, 95, 163, 0.01),
            123px 86px 90px rgba(102, 95, 163, 0.05),
            55px 38px 67px rgba(102, 95, 163, 0.09),
            14px 10px 37px rgba(102, 95, 163, 0.1),
            0px 0px 0px rgba(102, 95, 163, 0.1);
          border-radius: 10px;
          padding: 42px 25px;
          text-align: left;
          .header {
            margin: 0;
            font-style: normal;
            font-weight: $large-weight;
            line-height: 36px;
            color: #ffffff;
          }
          .subHeader {
            margin: 0;
            font-style: normal;
            font-weight: $normal-weight;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }
      .cardRight {
        padding: 40px 29px 18px 29px;
        height: fit-content;
        overflow: hidden;
        @media only screen and (min-width: $tablet) {
          width: 70%;
          padding: 38px 40px;
          height: 100%;
        }
        width: 100%;
        background: #ffffff;
        box-shadow: 218px 153px 106px rgba(26, 32, 42, 0.01),
          123px 86px 90px rgba(26, 32, 42, 0.05),
          55px 38px 67px rgba(26, 32, 42, 0.09),
          14px 10px 37px rgba(26, 32, 42, 0.1),
          0px 0px 0px rgba(26, 32, 42, 0.1);

        border-radius: 10px;
        text-align: left;
        .header {
          display: flex;
          align-items: baseline;
          height: fit-content;
          position: relative;
          sup {
            align-self: unset;
            position: absolute;
            top: -9px;
            left: -10px;
            font-style: normal;
            font-weight: $medium-weight;
            font-size: 27px;
            line-height: 32px;
            color: #000000;
          }
          .header1 {
            margin: 0;
            font-style: normal;
            font-weight: $large-weight;
            line-height: 60px;
            color: #000000;
          }
          .header2 {
            margin: 0;
            font-style: normal;
            font-weight: $medium-weight;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
          }
        }
        .subHeader {
          margin: 0;
          font-style: normal;
          font-weight: $medium-weight;
          line-height: 24px;
          color: #979797;
        }
        .list {
          margin: 0;

          li {
            list-style-image: url("../../../assets/icons/listCheck.svg");
            font-style: normal;
            font-weight: $normal-weight;
            font-size: $font-size-small;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #000000;
            margin: 6px 0;
          }
          li::marker {
            width: 17px;
            height: 17px;
          }
        }
        hr {
          margin-top: 37px;
          border: none;
          border-top: 1px dashed #000000;
        }
        .choosePlanBtn {
          display: block;
          min-width: 287px;
          margin-top: 15px;
          @media only screen and (min-width: $tablet) {
            min-width: 527px;
          }
          height: 66px;
          border: none;
          border-radius: 10px;
          font-style: normal;
          font-weight: $large-weight;
          font-size: 19px;
          line-height: 28px;
          margin-inline: auto;
        }
      }
    }
  }
}
