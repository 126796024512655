.candidate-video-container {
  height: 100vh;
  width: 100%;
  display: flex;
  .loader {
    flex-direction: row;
    column-gap: 5px;
    .css-cz9gq9-MuiCircularProgress-root {
      width: 30px !important;
      height: 30px !important;
    }
  }
  .uploadAlert {
    padding: 0 1rem 0 1rem;
    width: fit-content;
    height: 100%;
    line-height: normal;
    align-items: center;
    .css-1pxa9xg-MuiAlert-message {
      padding: 0;
      height: fit-content;
    }
  }
  .image-placeholder {
    height: 83px;
    width: 115px;
    filter: blur(8px);
    background: lightgrey;
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  .company-logo {
    height: 83px;
    width: 115px;
    object-fit: contain;
    position: relative;
  }

  .left-panel {
    width: 35%;
    height: 100%;
    .content-wrapper {
      height: inherit;
      .progress-section {
        height: 81px;
        display: flex;
        align-items: center;
        padding-inline: 24px;
        .progress-container {
          width: 100%;
          height: 100%;

          .progressBar {
            width: 100%;
            height: 16px;
            position: relative;
            .progress {
              height: 16px;
            }
            .progressIndicator {
              position: absolute;
              top: -5px;
              width: 58px;
              height: 25px;
              background: #000000;
              border: 1px solid #000000;
              border-radius: 10px;
              @include centerElements;
              column-gap: 2px;
              img {
                width: 12px;
                height: 12px;
              }
              p {
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 20px;
                color: #ffffff;
              }
            }
          }
        }
      }
      .question-section {
        border-right: 5px solid #f4f4f4;
        padding-inline: 24px;
        .question-number {
          font-style: normal;
          font-weight: $large-weight;
          font-size: 22px;
          line-height: 36px;
        }
        .duration {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 29px;
          color: #747474;
        }
        .ques-container {
          overflow-y: auto;
          height: 100%;
          margin: 0;
          @include scrollStyle(8px, auto);
        }
        .questionText {
          font-style: normal;
          font-weight: $medium-weight;
          font-size: $font-size-small;
          line-height: 24px;
        }
      }
      .bottom-section {
        height: 90px;
        padding: 10px;
      }
    }
  }
  .right-panel {
    width: 65%;
    height: inherit;
    padding: 10px 24px;

    .uploadAlert {
      width: fit-content;
      height: 100%;
      line-height: normal;
      align-items: center;
      .css-1pxa9xg-MuiAlert-message {
        padding: 0;
        height: fit-content;
        margin-block: auto;
      }
    }
    .header {
      font-style: normal;
      font-weight: $large-weight;
      font-size: 20px;
      line-height: normal;
      color: #747474;
    }
    .video-options-container {
      border: 1px solid #e4e4e4;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      height: 60px;
      padding-inline: 38px;
      .time-container {
        .timeText {
          font-style: normal;
          font-weight: $large-weight;
          font-size: $font-size-small;
          line-height: 24px;
        }
        .time {
          font-style: normal;
          font-weight: $large-weight;
          font-size: 24.4706px;
          line-height: 37px;
        }
      }
      button {
        width: 180px;
        height: 43px;
        border-radius: 10px;
        font-style: normal;
        font-weight: $large-weight;
        font-size: $font-size-small;
        line-height: 24px;
      }
      .submitBtn-color {
        background-color: #ff0000;
      }
      .skipBtn {
        border: 0.5px solid #aeaeae;
      }
    }
    .sticky-video {
      position: absolute;
      left: 10px;
      bottom: 10px;
      height: 43%;
      .video-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        .record-tag {
          position: absolute;
          right: 16px;
          top: 16px;
          z-index: 1;
          width: fit-content;
          .recording-circle {
            background-color: red;
            min-width: 12px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            animation: blink-animation 1s steps(5, start) infinite;
            -webkit-animation: blink-animation 1s steps(5, start) infinite;
          }

          @keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
          @-webkit-keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
        }
        video {
          height: 100%;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          border-radius: 25px;
          overflow: hidden;
        }
      }
    }
    .video-container {
      position: relative;
      border-radius: 25px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      @include centerElements;
      flex-direction: column;
      .video-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        .record-tag {
          position: absolute;
          right: 16px;
          top: 16px;
          z-index: 1;
          width: fit-content;
          .recording-circle {
            background-color: red;
            min-width: 12px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            animation: blink-animation 1s steps(5, start) infinite;
            -webkit-animation: blink-animation 1s steps(5, start) infinite;
          }

          @keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
          @-webkit-keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
        }

        video {
          width: 100%;
          object-fit: contain;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          border-radius: 25px;
          overflow: hidden;
        }
      }
    }
    .answer-container {
      padding-block: 25px;
      height: calc(100vh - 107px);
    }
    .myCodeMirror {
      .cm-content {
        white-space: pre-wrap;
        word-break: normal;
        word-wrap: break-word;
        max-width: 96%;
      }
    }
  }
}
