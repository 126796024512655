.summarization-container {
  .filter-container {
    display: flex;
    column-gap: 20px;
    align-items: center;
    padding-bottom: 15px;
    justify-content: flex-end;
  }
  .filter-sort-drawer {
    input {
      height: 20px;
    }

    .searchBar {
      .MuiFormControl-root {
        margin: 0;
      }

      .css-6lfaci-MuiFormControl-root-MuiTextField-root {
        margin: 0;
      }
    }
    .hiringStageDropdown {
      .MuiAutocomplete-option {
        font-size: 12px !important;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #212b36;
    }
  }
}
