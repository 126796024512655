.media-container {
  padding: 2rem;
  display: flex;
  min-height: 100vh;
  .media-container-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    background-color: $third-base-color;
    width: 100%;
    border-radius: 6px;
    .testdojo-logo {
      .image-placeholder {
        width: 115px;
        height: 60px;
        filter: blur(8px);
        background: lightgrey;
        opacity: 0.7;
        animation: skeleton-loading 1s linear infinite alternate;
      }
      .company-logo {
        height: 60px;
        width: 115px;
        object-fit: contain;
        position: relative;
      }
    }
    .video-access-section {
      display: flex;
      .instructions {
        width: 100%;
        display: flex;
        flex-direction: column;
        h1 {
          font-style: normal;
          font-weight: $medium-weight;
          font-size: 24px;
          line-height: 35px;
          color: #000000;
        }
        .point {
          font-style: normal;
          font-weight: $normal-weight;
          font-size: 1rem;
          margin-top: 16px;
          color: #000000;
          span {
            font-size: 1rem;
          }
          .text {
            padding-left: 5px;
          }
          .subText {
            padding-left: 27px;
          }
        }
      }
      .preview {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .media-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 50px;
          .permission-denied {
            width: 100%;
            background-color: #f0d6bb;
            display: flex;
            flex-direction: row !important;
            padding: 10px;
            column-gap: 30px;

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 30px;
              color: #000000;
              margin: 17px 0px;
            }
          }

          .videoElement {
            object-fit: contain;
            border-radius: 6px;
            height: 300px;
          }

          .hide-photo {
            opacity: 0;
            pointer-events: none;
          }
        }

        .video-instruction {
          background-color: #c8d7de;

          font-style: normal;
          font-weight: $normal-weight;
          font-size: 14px;
          margin: 3rem 0rem 0rem 0rem;
          line-height: 30px;
          color: #000000;
          padding: 1.2rem;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          h5 {
            font-size: $font-size-medium;
          }
        }
        button {
          position: relative;
          width: 231px;
          height: 62px;
          // background: $primary-color;
          border-radius: 6.95686px;
          border: transparent;

          font-style: normal;
          font-weight: $medium-weight;
          font-size: $font-size-medium;
          line-height: 28px;
          text-align: center;
          color: $third-base-color;
        }
      }
    }
  }
}
