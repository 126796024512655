.feedback-container {
  margin-top: 30px;
  display: flex;
  max-width: 1218px;
  justify-content: center;
  padding-bottom: 30px;
  align-items: center;

  .feedback-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
  }

  .feedback-sub-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    color: #5c5b5b;
  }

  .feedback-illustrator {
    margin-top: 75px;
    width: 558.37px;
    height: 409px;
    object-fit: cover;
  }

  .emoji-container {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    .image-container {
      display: inline-block;
      text-align: center;
      .emoji-logo {
        width: 68px;
        height: 68px;
      }
    }
  }
  .tags-container {
    padding: 0.2rem;
    border-radius: 10px;
    font-style: normal;
    font-weight: $large-weight;
    white-space: nowrap;
    font-size: 14px;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  .feedback-button {
    width: 10rem;
    height: 3rem;
    font-size: 1rem;
  }

  .MuiFormHelperText-root {
    position: absolute;
    right: 0;
    top: 114px;
    padding: 0.1rem 0 0 0;
  }
}
