.trial-interview-container {
  display: flex;
  height: 100vh;
  .left-panel {
    width: 35%;
    height: 100%;
    padding-block: 1rem;
    .question-section {
      height: 100%;
      border-right: 5px solid #f4f4f4;
      padding-inline: 24px;
      overflow: hidden auto;
      @include scrollStyle(8px, auto);
      .duration {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 29px;
        color: #747474;
      }
    }
    .instrunctions-container {
      padding-top: 1rem;
    }
  }
  .right-panel {
    width: 65%;
    height: inherit;
    padding: 10px 24px;
    .uploadAlert {
      width: fit-content;
      height: 100%;
      line-height: normal;
      align-items: center;
      .css-1pxa9xg-MuiAlert-message {
        padding: 0;
        height: fit-content;
        margin-block: auto;
      }
      .css-1jnbx3k-MuiAlert-icon {
        padding: 0;
      }
    }
    .header {
      font-style: normal;
      font-weight: $large-weight;
      font-size: 20px;
      line-height: normal;
      color: #747474;
    }
    .video-options-container {
      margin-top: 1rem;
      border: 1px solid #e4e4e4;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      height: 60px;
      padding-inline: 38px;
      .time-container {
        .timeText {
          font-style: normal;
          font-weight: $large-weight;
          font-size: $font-size-small;
          line-height: 24px;
        }
        .time {
          font-style: normal;
          font-weight: $large-weight;
          font-size: 24.4706px;
          line-height: 37px;
        }
      }
      button {
        width: 180px;
        height: 43px;
        border-radius: 10px;
        font-style: normal;
        font-weight: $large-weight;
        font-size: $font-size-small;
        line-height: 24px;
      }
      .submitBtn-color {
        background-color: #ff0000;
      }
    }
    .trialResponse-container {
      margin-top: 1rem;
      padding-bottom: 1rem;
      height: calc(100vh - 132px);
      display: flex;
      .video-container-preview {
        width: 50%;
        height: 50%;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        video {
          width: 100%;
          height: 100%;
        }
      }

      hr {
        margin-inline: 1rem;
      }
      .transcript-container {
        width: 50%;
        height: 100%;
        overflow: hidden auto;
        @include scrollStyle(8px, auto);
        padding: 1rem;
      }
    }
    .video-container {
      position: relative;
      padding-block: 25px;
      height: calc(100vh - 114px);
      overflow: hidden;
      @include centerElements;
      flex-direction: column;
      .video-wrapper {
        width: fit-content;
        height: 100%;
        position: relative;
        .record-container {
          position: absolute;
          right: 15px;
          top: 15px;
          z-index: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 0.25em;
          border-radius: 0.25em;
          width: fit-content;
          .recording-circle {
            background-color: red;
            min-width: 12px;
            height: 12px;
            border-radius: 50%;
            animation: blink-animation 1s steps(5, start) infinite;
            -webkit-animation: blink-animation 1s steps(5, start) infinite;
            margin-right: 5px;
          }

          .recording-text {
            font-size: 1rem;
            color: white;
          }
          @keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
          @-webkit-keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
        }

        video {
          height: 100%;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          border-radius: 25px;
          overflow: hidden;
        }
      }
    }
  }
}
