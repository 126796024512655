.createTest-container {
  height: calc(100vh - 158px);
  .content-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100% - 94px);
    position: relative;
    margin-top: 32px;
    padding: 16px;
    background-color: #fff;
    border-radius: 12px;
    border: 2px solid rgb(241 241 241 / 73%);
    box-shadow: 4px 4px 10px 0px rgba(176, 174, 174, 0.25);
    .sampleQuestions-wrapper {
      width: -webkit-fill-available;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .header {
        margin: 0;
        line-height: normal;
      }
    }
    .questions-container {
      display: flex;
      column-gap: 10px;
      padding-right: 2px;
      height: calc(100% - 50px);
      .prompt2 {
        width: 70%;
        max-height: inherit;
        ol {
          padding-left: 25px;
        }
        @include scrollStyle(8px, auto);
      }
      .table-wrapper {
        width: 70%;
        height: fit-content;
        min-height: 100%;
        padding: 16px;
        box-shadow: 0px 2px 3px -1px rgba(145, 158, 171, 0.08),
          0px 4px 6px 0px rgba(145, 158, 171, 0.06),
          0px 1px 6px 1px rgba(145, 158, 171, 0.04);
      }
      .questions-library-container {
        width: 30%;
        height: fit-content;
        min-height: 100%;
        max-height: fit-content;
        height: fit-content;
        box-shadow: 0px 2px 3px -1px rgba(145, 158, 171, 0.08),
          0px 4px 6px 0px rgba(145, 158, 171, 0.06),
          0px 1px 6px 1px rgba(145, 158, 171, 0.04);
        @include scrollStyle(8px, auto);
        .quesIndicator {
          color: $primary-color;
        }
      }
    }

    .textareaDisabled {
      background-color: #f5f5f5;
      opacity: 0.6;
    }

    .test-details-section {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 5px;
      row-gap: 10px;
      flex-grow: 1;

      .header-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        flex-shrink: 0;
      }
      .jobDescriptionTextArea {
        width: 100%;
        max-width: 100%;
        overflow: auto !important;
        height: calc(100% - 108px) !important;
        flex-grow: 1;
      }
      .jobDescription-bottom-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
        .charLimitText {
          margin-left: auto;
        }
        button {
          min-width: 242.44px;
          .button-text-wrapper {
            display: flex;
            column-gap: 5px;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    textarea {
      font-size: 1rem;
    }
    .skills-container {
      padding-bottom: 16px;
      .header {
        margin: 0;
        line-height: normal;
      }
      .header-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        flex-shrink: 0;
      }
      .prompt {
        max-height: 80px;
        display: flex;
        gap: 10px;
        overflow-y: auto;
        flex-wrap: wrap;
      }
      .add-skill-container {
        margin-top: 16px;
        display: flex;
        align-items: center;
        column-gap: 5px;
        button {
          height: 40px;
          align-self: flex-start;
        }
      }
      .selected-skill-container {
        margin-top: 24px;

        .skills-header-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .selected-skill-card {
          box-shadow: 0px 2px 3px -1px rgba(145, 158, 171, 0.08),
            0px 4px 6px 0px rgba(145, 158, 171, 0.06),
            0px 1px 6px 1px rgba(145, 158, 171, 0.04);
          margin-top: 8px;
          padding: 16px;
          justify-content: space-between;
          display: flex;
          column-gap: 10px;
          align-items: center;
          .no-of-ques-input {
            width: 60px;
          }
        }
      }
    }
    .screening-question-container {
      height: 100%;
      .customFormControlLabel > span:nth-of-type(2) {
        width: 100%;
      }
      .normalFormControlLabel > span:nth-of-type(1) {
        width: 100%;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        h4 {
          line-height: normal;
          margin: 0;
        }
      }
      .addScreeningBtn {
        display: block;
        margin-inline: auto;
      }
      @include scrollStyle(8px, auto);
    }
    .review-container {
      height: 100%;
      .header {
        display: flex;
        justify-content: space-between;
      }
      .review-content {
        margin-top: 1rem;
      }
      .quesIndicator {
        color: $primary-color;
      }
    }
    .remove-div {
      display: none;
    }
  }

  .button-container {
    display: flex;
    column-gap: 5px;
    .prevBtn,
    .nextBtn {
      min-width: 15ch;
      height: 50px;
    }
  }
}
