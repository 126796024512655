// color
// $primary-color: #665fa3;
// $secondary-color: #eaedf2;
$primary-color: #00ab55;
$secondary-color: #fff;
$third-base-color: #ffffff;

// spacing
$right-spacing: 2.813rem;
$left-spacing: 2.813rem;
$horizontal-spacing: 2.813rem;
$mobile-horizontal-spacing: 1rem;
$top-spacing-wb: 1.509rem; //with button
$top-spacing: 4.375rem; //without button
$button-spacing: 1.875rem;

//font family
$poppins-font: "Poppins", sans-serif;
$open-sans-font: "Open Sans", sans-serif;

//font Size
$font-size-small: 1rem;
$font-size-medium: 1.188rem;
$font-size-large: 1.5rem;

//font weight
$large-weight: 600;
$medium-weight: 500;
$normal-weight: 400;

//media query
$tablet: 48rem;
$laptop: 64rem;
$large-devices: 87.5rem;

$main-navigationbar: 87.97px;
