.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  p {
    margin: 0;
    font-size: $font-size-small;
    font-weight: $normal-weight;
    color: #818087;
  }
}
