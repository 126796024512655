@mixin fontStyle {
  font-weight: $medium-weight;
  font-size: $font-size-small;
  line-height: 22px;
}

.myApplicants-container {
  height: 100%;
  .motionDiv {
    height: 100%;
  }
  .empty-test-container {
    height: 100%;
    .searchText {
      font-style: normal;
      font-weight: $medium-weight;
      font-size: 1.125rem;
      color: #747474;
      margin: 0;
    }
  }
}
