.link-generation-container {
  position: relative;
  padding-bottom: 1rem;
  .header-container {
    border-radius: 8px;
    width: 100%;
    height: 4.563rem;
    padding: 1rem;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    h6 {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 1.75rem;
      font-size: $font-size-small;
      line-height: 28px;
    }
  }
}
